<template>
  <div class="main_content">
    <top_view></top_view>
    <div class="main">
      <div class="content" style="background-color:transparent;padding:0;">
        <div class="tab-item tab-item-div">
          <div class="filter-box" style="background-color: #fff;flex: 1;">
            <div class="top_title">
              <div class="left_line"></div>
              <div class="title_text">数据概括</div>
              <div class="bottom_line"></div>
            </div>
            <div class="top_content_list">
              <div class="top_content_item" v-for="(v,k) in dataSummary" :key="k">
                <div class="icon">
                  <el-image :src="v.icon"></el-image>
                </div>
                <div class="re_view">
                  <div class="num">
                    <div class="text" :style="{color:v.color}">{{ v.num }}</div>
                    <div class="company">{{ v.company }}</div>
                  </div>
                  <div class="title_name">{{ v.title }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="btn_list">
            <el-image :src="require('@/views/index/image/zccs@2x.png')"
                      @click="goToView('/index/policy_market')"></el-image>
            <el-image :src="require('@/views/index/image/grzx@2x.png')" @click="goUser"></el-image>
          </div>
        </div>
      </div>

      <div class="banner">
        <el-image class="banner1" @click="onBanner(1)" :src="require('@/views/index/image/banner@2x.png')"></el-image>
        <el-image class="banner2" @click="onBanner(2)" :src="require('@/views/index/image/组 152@2x.png')"></el-image>
      </div>
      <div class="content">
        <div class="tab-item">

          <div class="top_title">
            <div class="left_line"></div>
            <div class="title_text">最新条款</div>
            <div class="btn_text" @click="goToView('/index/policy_market')">查看更多 ></div>
            <div class="bottom_line"></div>
          </div>
          <policy_list></policy_list>
        </div>
      </div>
    </div>

    <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="30%" :modal="false"
        top="50px">

      <div style="text-align:center"><img :src="require('@/views/index/image/modal_warning.png')" alt=""></div>
      <div class="modalContent">
        <p class="subTitle" style="text-align:center;color:#ff8811;padding: 10px;">温馨提示</p>
        <div class="text-center">
          <div class="loginTip" style="text-align: left;">
            “龙岩市惠企政策平台”注册和登录功能现由“福建省社会用户实名认证和授权平台”提供统一支持，用户在首次登录时，需注册账号方可使用。完成账号注册后，按页面提示登录系统即可。具体操作步骤如下：<br>（1）点击“注册”，跳转注册界面<br>（2）选择注册类别，填写相关信息，完成注册，跳转登录页面<br>（3）按需选择登录方式登录即可<br>
            <div style="text-indent: 1em;">若有注册、登录问题，请致电0591-62623959，感谢您的理解与支持。</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="text-align:center">
    <el-button type="primary" @click="handleClick">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import policy_list from "@/views/index/home/compoents/policy_list";
import TWEEN from '@tweenjs/tween.js';

export default {
  name: "home",
  components: {policy_list},
  data: () => {
    return {
      dialogVisible: false,
      dataSummary: [
        {
          icon: require("@/views/index/image/ico-syqy@2x.png"),
          num: 0,
          company: "条",
          title: "条款总数",
          color: "#3988FF"
        },
        {
          icon: require("@/views/index/image/ico-fbbm@2x.png"),
          num: 0,
          company: "个",
          title: "发布部门",
          color: "#D92414"
        },
        {
          icon: require("@/views/index/image/ico-tszc@2x.png"),
          num: 0,
          company: "条",
          title: "免申条款数",
          color: "#2EA6A6"
        }
      ]
    }
  },
  async mounted() {
    requestAnimationFrame(this.animate)
    const totalNum = (await this.$http.sPost("/app/huiqiclause/list", {page: 1, limit: 1})).page.totalCount;
    // this.dataSummary[0]
    const exemptionNum = (await this.$http.sPost("/app/huiqiclause/list", {
      page: 1,
      limit: 1,
      cashType: '免申即享',
    })).page.totalCount;
    const departmentNum = (await this.$http.sPost("/app/dept/selectList")).list.length;

    const coords = {totalNum: 0, exemptionNum: 0, departmentNum: 0}
    new TWEEN.Tween(coords)
        .to({totalNum, exemptionNum, departmentNum}, 300)
        .easing(TWEEN.Easing.Quadratic.Out)
        .onUpdate(() => {
          this.dataSummary[0].num = Math.trunc(coords.totalNum);
          this.dataSummary[1].num = Math.trunc(coords.departmentNum);
          this.dataSummary[2].num = Math.trunc(coords.exemptionNum);
        }).start();

  },
  watch: {},
  methods: {
    onBanner(type = 1) {
      if (type === 1) window.open('https://mztapp.fujian.gov.cn:8304/dataset/UnifiedController/goLogin.do?callerCode=2c9bbe6977d84c2c0177dc15fd66003d2c9bbe6977d84c2c&returnurl=https://www.flongyan.cn/financial_portal/');
      else if (type === 2) window.open('http://www.longyan.cn/wsbs/jsp/public/hqzcService.jsp');
    },
    goToView(path, query) {
      this.$router.push({path, query})
    },
    animate(time) {
      requestAnimationFrame(this.animate)
      TWEEN.update(time)
    },
    goUser() {
      const username = window.sessionStorage.getItem("username");
      if (typeof username != "string") this.dialogVisible = true;
      else this.goToView('user');
    },


    handleClick() {
      let origin = window.location.origin
      let redirect_uri = encodeURIComponent(origin + "/#/index?loginflag=true")
      let callerCode = "2c9bbe698172a06201824295293e0b5f2c9bbe698172a062";
      window.location.href = `https://mztapp.fujian.gov.cn:8304/dataset/UnifiedController/goLogin.do?callerCode=${callerCode}&returnurl=${redirect_uri}`;
    },
  }
}
</script>

<style lang="scss" scoped>

.main_content {
  background: #f0f4f8;
}

.main {
  max-width: 1300px;
  margin: 0 auto;

  .banner {
    width: 100%;
    margin-top: 20px;
    display: flex;

    .banner1 {
      cursor: pointer;
      user-select: none;
      width: 660px;
      height: 120px;

      &:hover {
        opacity: 0.8;
      }
    }

    .banner2 {

      cursor: pointer;
      user-select: none;
      width: 660px;
      height: 120px;
      margin-left: 20px;

      &:hover {
        opacity: 0.8;
      }
    }

  }

  .top {
    padding-top: 96px;
    padding-bottom: 70px;

    .logo {
      width: 474px;
      margin: 0 auto;
    }
  }

  .search {
    .search-box {
      background-color: white;
      max-width: 900px;
      margin: 0 auto;
      border-radius: 4px;
      overflow: hidden;
      padding: 1px 0 1px 1px;

      ::v-deep .el-input__inner {
        height: 54px;
        line-height: 54px;
      }

      ::v-deep .el-input__inner::placeholder {
        font-size: 18px;
        color: #aaaaaa;
      }
    }
  }

  .content {
    //padding: 20px 0;
    background-color: white;
    // border-radius: 10px;
    margin-top: 20px;

    ::v-deep .el-tabs__item {
      font-size: 24px;
      height: 50px;
      position: relative;
    }

    ::v-deep .el-tabs__active-bar {
      background-color: #1156a3;
    }

    ::v-deep .el-tabs__item.is-active {
      font-weight: bold;
      color: #1156a3;
    }

    ::v-deep .el-tabs__content {
      overflow: inherit;
    }

    .tab-item-div {
      display: flex;
      align-items: center;

      .btn_list {
        margin-left: 16px;
        flex: 0;
        user-select: none;

        .el-image:first-child {
          margin-bottom: 16px;
        }

        .el-image {
          width: 264px;
          height: 96px;
          cursor: pointer;
        }

        .el-image:hover {
          opacity: 0.5;
        }

      }
    }

    .tab-item {
      position: relative;

      .top_title {
        display: flex;
        align-content: center;
        width: 100%;
        position: relative;
        padding: 15px 0 15px 0;

        .left_line {
          width: 4px;
          height: 20px;
          background: #1156A3;
          border-radius: 0px 4px 4px 0px;
        }

        .bottom_line {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 1px;
          background: #DDDDDD;
        }

        .title_text {
          font-size: 18px;
          font-family: Microsoft YaHei, serif;
          font-weight: bold;
          color: #333333;
          line-height: 19px;
          margin-left: 11px;
        }

        .btn_text {
          font-size: 16px;
          font-family: Microsoft YaHei, serif;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
          margin-left: auto;
          margin-right: 20px;

          cursor: pointer;
          user-select: none;

          &:hover {
            opacity: 0.8;
          }
        }
      }


      .search-box {
        position: absolute;
        width: 500px;
        top: -64px;
        right: 0;
        z-index: 10;
      }

      .filter-box {
        display: flex;
        flex-direction: column;
        position: relative;

        .top_content_list {
          display: flex;
          justify-content: center;
          margin-right: 30px;

          .top_content_item {
            flex: 1;
            display: flex;
            height: 160px;
            background: #FFFFFF;
            align-items: center;


            .re_view {
              margin-left: 19px;

              .title_name {
                font-size: 16px;
                font-family: Microsoft YaHei, serif;
                font-weight: 400;
                color: #AAAAAA;
              }

              .num {
                display: flex;
                align-items: baseline;
                white-space: nowrap;

                .company {
                  margin-left: 10px;
                  margin-top: 18px;
                  font-size: 16px;
                  font-family: Microsoft YaHei, serif;
                  font-weight: 400;
                  color: #AAAAAA;
                }

                .text {
                  font-size: 30px;
                  font-family: Microsoft YaHei, serif;
                  font-weight: bold;
                  color: #3988FF;
                }

              }
            }

            .icon {
              width: 54px;
              height: 51px;
              margin-left: 30px;
            }
          }
        }

      }

      .policy-list {
        margin-top: 10px;

        .policy-item:hover {
          background-color: #f5f5f5;
        }

        .policy-item {
          padding: 10px 20px 20px;
          display: flex;
          border-bottom: solid 1px #e5e5e5;
          cursor: pointer;
          position: relative;
          align-items: center;

          .item_btn {
            width: 144px;
            height: 48px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.blue {
              background: #1156A3;
              color: #FFFFFF;
            }

            &.white {
              border: 1px solid #1156A3;
              background: #fff;
              color: #1156A3;
            }
          }

          .item-left {
            flex-grow: 1;
            padding-right: 20px;
            line-height: 1.5;
            display: flex;
            flex-direction: column;
            flex: 1;
            width: 0;


            .title {
              font-size: 26px;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .tag {
              margin-top: 10px;
              width: 100%;
              overflow: hidden;

              .el-tag {
                margin-right: 10px;
                display: inline-block;
              }
            }

            .info {
              display: flex;
              font-size: 16px;
              color: #888;
              margin-top: 10px;

              .el-image {
                vertical-align: middle;
              }

              .info-center {
                margin-left: 30px;
                max-width: 600px;
              }

              .info-right {
                text-align: left;
                margin-left: 30px;
              }
            }
          }

        }
      }
    }
  }
}
</style>
